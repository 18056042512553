import React, { useState, useEffect } from "react";
import { Slider, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomSlider = ({ name, control,param, defaultValue = 0, ...props }) => {
  const [sliderValue, setSliderValue] = useState(defaultValue);

  useEffect(() => {
    setSliderValue(defaultValue);
  }, [defaultValue]);
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <div>
            <div style={{ color:`rgba(255, 255, 255, 0.4)` }}>
              Batch Inverval: {sliderValue}
            </div>
            <Slider
              {...field}
              {...props}
              value={sliderValue}
              step={15}
              min={15}
              max={180}
              onChange={(e, value) => {
                setSliderValue(value);
                field.onChange(value); 
              }}
              label='Value'
              disabled={!param.disable}
            />

          </div>
        )}
      />
    </div>
  );
};

export default CustomSlider;
