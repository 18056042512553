import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import CustomMultiSelectCloudEndpointsNew from "../../../common/CustomMultiSelectCloudEndpointsNew";
import CustomInputConnectionString from "../../../common/CustomInputDeviceConnection";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import CustomSlider from "../../../common/CustomSlider";
import { Modal } from "antd";
import { TextField } from "@mui/material";
import {
  createAzureDeviceAction,
  editCloudEndpointAzureAction,
  getCloudEndpointDeviceListAction,
} from "../../../redux/action/action";
import CustomToggle from "../../../common/CustomToggle";
import { azureSchema } from "../../../utils/validations/cloudEndpointsValidation";

const AddAzureCloudEndpoint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const [param, setParam] = useState({ disable: true });
  const [hubConnectionString, setHubConnectionString] = useState("");
  const [hubDeviceId, setHubDeviceId] = useState("");
  const [openDeviceModal, setOpenDeviceModal] = useState(false);

  const handleDeviceSubmit = async () => {
    dispatch(
      createAzureDeviceAction({
        hubDeviceId,
      })
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ resolver: yupResolver(azureSchema) });

  const defaultValue = [
    {
      id: 146,
      name: "ICX7150-C12-Switch",
      type: "asset",
    },
  ];

  const onSubmit = (data) => {
    console.log("Form submitted with data:", data);
    const devices = watch("devices") || [];
    const formattedDevices = devices
      .map((id) => {
        const selectedDevice = cloudEndpointsDeviceList.find(
          (device) => device.id === id
        );
        return {
          id: selectedDevice?.id,
          name: selectedDevice?.name,
          type: selectedDevice?.type,
        };
      })
      .filter((device) => device);

    const payload = {
      ...data,
      devices: formattedDevices,
    };

    console.log("Formatted payload:", payload);
    dispatch(editCloudEndpointAzureAction(payload));
    setTimeout(() => {
      navigate("/cloud-endpoints");
    }, 1000);
  };

  useEffect(() => {
    dispatch(getCloudEndpointDeviceListAction());
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoint-options")}
              />
              Create Cloud Endpoint
            </h3>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpointsNew
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name="devices"
                      multiple={true}
                      setValue={setValue}
                      watch={watch}
                      disabled={!param.disable}
                      error={errors?.devices}
                      defaultValue={defaultValue}
                    />
                  </div>
                </div>
                <hr className="black-hr" />

                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      disabled={!param.disable}
                      defaultValue={false}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      defaultValue={watch("batchInterval") || "15"}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
          
                <div className="row">
                  <CustomInputConnectionString
                    label={"Device Connection String"}
                    varient="outline"
                    control={control}
                    setValue={setValue}
                    name="deviceConnectionString"
                    error={errors.deviceConnectionString}
                    param={param}
                    disabled={!param.disable}
                  />
                </div>
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />
              <Modal
                open={openDeviceModal}
                onCancel={() => setOpenDeviceModal(false)}
                onOk={handleDeviceSubmit}
              >
                <TextField
                  margin="normal"
                  label="Connection String"
                  fullWidth
                  variant="outlined"
                  value={hubConnectionString}
                  onChange={(e) => setHubConnectionString(e.target.value)}
                />
                <TextField
                  margin="normal"
                  label="Device Id"
                  fullWidth
                  variant="outlined"
                  value={hubDeviceId}
                  onChange={(e) => setHubDeviceId(e.target.value)}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddAzureCloudEndpoint;
