import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import CustomMultiSelectCloudEndpointsNew from "../../../common/CustomMultiSelectCloudEndpointsNew";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import CustomSlider from "../../../common/CustomSlider";
import CustomToggle from "../../../common/CustomToggle";
import {
  getCloudEndpointDeviceListAction,
  editCloudEndpointAWSAction,
} from "../../../redux/action/action";
import { awsSchema } from "../../../utils/validations/cloudEndpointsValidation";
import CustomPrivateKey from "../../../common/CustomPrivateKey";
import CustomRootCertificate from "../../../common/CustomRootCertificate";
import CustomClientCertificate from "../../../common/CustomClientCertificate";

const CreateAmazonIoT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const [param, setParam] = useState({ disable: true });
  const [privateKeyData, setPrivateKeyData] = useState({
    privateKey: null,
    privateKeyName: "",
  });
  const [rootCertificateData, setRootCertificateData] = useState({
    rootCertificate: null,
    rootCertificateName: "",
  });
  const [clientCertificateData, setClientCertificateData] = useState({
    clientCertificate: null,
    clientCertificateName: "",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(awsSchema),
  });
  const defaultValue = [
    {
      id: 146,
      name: "ICX7150-C12-Switch",
      type: "asset",
    },
  ];

  const onSubmit = (data) => {
    const devices = watch("devices") || [];
    const formattedDevices = devices
      .map((id) => {
        const selectedDevice = cloudEndpointsDeviceList.find(
          (device) => device.id === id
        );
        return {
          id: selectedDevice?.id,
          name: selectedDevice?.name,
          type: selectedDevice?.type,
        };
      })
      .filter((device) => device);

    const payload = {
      ...data,
      devices: formattedDevices,
      privateKey: privateKeyData.privateKey,
      privateKeyName: privateKeyData.privateKeyName,
      rootCertificate: rootCertificateData.rootCertificate,
      rootCertificateName: rootCertificateData.rootCertificateName,
      clientCertificate: clientCertificateData.clientCertificate,
      clientCertificateName: clientCertificateData.clientCertificateName,
    };

    console.log("Formatted payload:", payload);
    dispatch(editCloudEndpointAWSAction(payload));
    if (
      !privateKeyData.privateKey ||
      !privateKeyData.privateKeyName ||
      !rootCertificateData.rootCertificate ||
      !rootCertificateData.rootCertificateName ||
      !clientCertificateData.clientCertificate ||
      !clientCertificateData.clientCertificateName
    ) {
      return;
    } else {
      setTimeout(() => {
        navigate("/cloud-endpoints");
      }, 1000);
    }
  };

  useEffect(() => {
    dispatch(getCloudEndpointDeviceListAction());
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoint-options")}
              />
              Create Cloud Endpoint
            </h3>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpointsNew
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name="devices"
                      multiple={true}
                      setValue={setValue}
                      watch={watch}
                      disabled={!param.disable}
                      error={errors?.devices}
                      defaultValue={defaultValue}
                    />
                  </div>
                </div>
                <hr className="black-hr" />

                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      disabled={!param.disable}
                      defaultValue={false}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      defaultValue={watch("batchInterval") || "15"}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <hr className="black-hr" />

                <div className="col-sm-12">
                  <div
                    style={{ color: `rgba(255, 255, 255, 0.4)` }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    Amazon IoT
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Region"}
                      varient="outline"
                      control={control}
                      disabled={!param.disable}
                      defaultValue=""
                      name="region"
                      error={errors?.region}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Client Id"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="clientId"
                      error={errors?.clientId}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Topic"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="topic"
                      error={errors?.topic}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6"></div>
                <hr className="black-hr" />

                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <div form-floating cus-floating-inputs mr-b30>
                      <CustomPrivateKey
                        disabled={!param.disable}
                        param={param}
                        initialFileData={{
                          privateKey: [],
                          privateKeyName: "",
                        }}
                        onFilesChange={setPrivateKeyData}
                        error={errors?.privateKey}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomRootCertificate
                      disabled={!param.disable}
                      param={param}
                      initialFileData={{
                        rootCertificate: [],
                        rootCertificateName: "",
                      }}
                      onFilesChange={setRootCertificateData}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomClientCertificate
                      disabled={!param.disable}
                      param={param}
                      initialFileData={{
                        clientCertificate: [],
                        clientCertificateName: "",
                      }}
                      onFilesChange={setClientCertificateData}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-floating cus-floating-inputs mr-b30"></div>
                </div>
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateAmazonIoT;
