import React, { useState, useEffect } from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const CustomRootCertificate = ({ param = { disable: true }, initialFileData, onFilesChange }) => {

  const [fileData, setFileData] = useState(initialFileData);

  const beforeUpload = (file) => true; 

  const handleChange = (info) => {
    const updatedFile = info.fileList[0];

    if (updatedFile) {
      console.log('Updated file:', updatedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

  
        setFileData({
          rootCertificate: base64String,
          rootCertificateName: updatedFile.name,
        });

        console.log('New file data:', {
          rootCertificate: base64String,
          rootCertificateName: updatedFile.name,
        });
      };
      reader.readAsDataURL(updatedFile.originFileObj);
    } else {
      setFileData({ rootCertificate: null, rootCertificateName: '' });
    }
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess(null, file);
    }, 1000);
  };

  useEffect(() => {
    onFilesChange(fileData); 
  }, [fileData, onFilesChange]);


  useEffect(() => {
    setFileData(initialFileData);
  }, [!initialFileData]);

 
  return (
    <div>
      <Upload
        fileList={fileData.rootCertificateName ? [{ name: fileData.rootCertificateName, status: 'done' }] : []}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        showUploadList={true}
        disabled={!param.disable}
        customRequest={handleUpload}
        maxCount={1}
        onRemove={false}
        // onRemove={() => {
        //   setFileData({ rootCertificate: null, rootCertificateName: '' });
        // }}
      >
       <Button
          // style={{ color: `rgba(255, 255, 255, 0.4)`, width: 300 }}
          type="dashed"
          icon={<UploadOutlined />}
          variant="link"
          disabled={!param.disable}
          color="primary"
          style={{ width: 300 }}
        >
          <UploadOutlined /> Choose Root Certificate*
        </Button>
      </Upload>
    </div>
  );
};

export default CustomRootCertificate;
