import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelectCloudEndpoints({
  options,
  label,
  name,
  multiple,
  disabled,
  setValue,
  defaultValue = [],
}) {
  const [selectedValue, setSelectedValue] = useState([]);

 
  useEffect(() => {
    if (defaultValue.length > 0) {
      const defaultSelected = options.filter(option => defaultValue.includes(option.id));
      setSelectedValue(defaultSelected);
      setValue(name, multiple ? defaultSelected : defaultSelected[0] || null);
    }
  }, [defaultValue, options, 
    setValue, 
    name, multiple]);

  const onChange = (event, newValue) => {
    setSelectedValue(newValue);
    setValue(name, multiple ? newValue : newValue[0] || null);
    };

  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple}
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedValue} 
      getOptionLabel={(option) => option.name}
      onChange={onChange} 
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label || ""} />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.id}
          value={option.name}
          sx={{ justifyContent: "space-between" }}
        >
          <Checkbox checked={selected} />
          {option.name}
        </MenuItem>
      )}
    />
  );
}
