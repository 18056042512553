import { Checkbox, Empty, Table, Tag } from "antd";
import React from "react";
import { expandedIcon } from "../../../utils/expanded";
import { CustomSearch } from "../../../common/UI/CustomSearch";

const AddNodeTable = ({
  list,
  setList,
  param,
  setParam,
  selectedRowKeys,
  setSelectedRowKeys,
  totalRecord,
  colums1,
}) => {
  const handleNestedCheckbox = (record, enpoint, checkBoxValue) => {
    let tempKeys = [...selectedRowKeys];

    const updatedCheckboxes = list.map((checkbox) => ({
      ...checkbox,
      connectedDevices:
        checkbox.id === record.id
          ? checkbox.connectedDevices.map((connectedRecord) =>
              enpoint.id === connectedRecord.id
                ? { ...connectedRecord, isChecked: checkBoxValue }
                : connectedRecord
            )
          : checkbox.connectedDevices,
    }));

    if (checkBoxValue) {
      tempKeys.push(record.id);
    } else {
      for (let device of updatedCheckboxes) {
        if (device.id === record.id) {
          const deviceList = device.connectedDevices.filter(
            (rec) => rec.isChecked == true
          );
          console.log(deviceList);
          if (!deviceList.length)
            tempKeys = tempKeys.filter((key) => key !== record.id);
        }
      }
    }

    setList(updatedCheckboxes);
    setSelectedRowKeys(tempKeys);
  };
  const handleNestedCheckboxAll = (record, checkBoxValue) => {
    let tempKeys = [...selectedRowKeys];

    if (checkBoxValue) {
      tempKeys.push(record.id);
    } else {
      tempKeys = tempKeys.filter((key) => key !== record.id);
    }

    const updatedCheckboxes = list.map((checkbox) => {
      if (checkbox.id === record.id) {
        const updatedConnectedDevices = checkbox.connectedDevices.map(
          (connectedRecord) => ({
            ...connectedRecord,
            isChecked: checkBoxValue,
          })
        );

        return {
          ...checkbox,
          isChecked: checkBoxValue,
          connectedDevices: updatedConnectedDevices,
        };
      }
      return checkbox;
    });

    setList(updatedCheckboxes);
    setSelectedRowKeys(tempKeys);
  };

  const colums = [
    {
      title: "Device Name",
      dataIndex: "device_name",
      sorter: true,
      filterMultiple: false, // Disable multiple filters for this column
      filters: [
        {
          text: "With Endpoints",
          value: "withEndpoints",
        },
        {
          text: "Without Endpoints",
          value: "withoutEndpoints",
        },
      ],
    },
    {
      title: "Trust Level",
      dataIndex: "trustLevel",
      // sorter: true,
      filterMultiple: false, // Disable multiple filters for this column
      filters: [
        {
          text: "Basic Trust",
          value: "1",
        },
        {
          text: "Intermediate Trust",
          value: "2",
        },
        {
          text: "High Trust",
          value: "3",
        },
      ],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data, record) => {
        return (
          <div>
            {data == "Active" ? (
              <Tag color="green">{data}</Tag>
            ) : (
              <Tag color="red">{data}</Tag>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Last Scannned",
    //   dataIndex: "last_modified",
    //   render: (data, record) => {
    //     return (
    //       <div>
    //         {record?.last_modified !== "-" ? (
    //           <span className="last_modified">{record?.last_modified} </span>
    //         ) : (
    //           "-"
    //         )}{" "}
    //       </div>
    //     );
    //   },
    // },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRow) => {
    const selectedKeys = newSelectedRowKeys.filter(
      (key) => !selectedRowKeys.includes(key)
    );
    if (selectedKeys?.length) {
      const updatedCheckboxes = list.map((checkbox) =>
        selectedKeys.includes(checkbox.id)
          ? {
              ...checkbox,
              isChecked: true,
              connectedDevices: checkbox.connectedDevices.map((record) =>
                true ? { ...record, isChecked: true } : record
              ),
            }
          : checkbox
      );
      setList(updatedCheckboxes);
    }

    const removedKeys = selectedRowKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );

    if (removedKeys?.length) {
      const updatedCheckboxes = list.map((checkbox) =>
        removedKeys.includes(checkbox.id)
          ? {
              ...checkbox,
              isChecked: false,
              connectedDevices: checkbox.connectedDevices.map((record) =>
                true ? { ...record, isChecked: false } : record
              ),
            }
          : checkbox
      );
      setList(updatedCheckboxes);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const nestedRecord = (record) => {
    return (
      <div>
        {record?.connectedDevices?.length ? (
          <>
            {" "}
            <div className="row endpointBottom nested-colaps">
              <div className="col-1 device-heading">
                <Checkbox
                  checked={record?.connectedDevices?.every(
                    (records) => records?.isChecked
                  )}
                  onClick={(e) =>
                    handleNestedCheckboxAll(record, e.target.checked)
                  }
                />
              </div>
              <div className="col-3 device-heading">
                <span>Endpoint Name</span>
              </div>

              <div className="col-3 device-heading">
                <span>IP Address</span>
              </div>
              <div className="col-2 device-heading">
                <span>Status</span>
              </div>
            </div>
            {record?.connectedDevices?.map((endpoint) => (
              <div
                className="row endpointBottom nested-colaps"
                key={endpoint.id}
              >
                <div className="col-1">
                  <Checkbox
                    checked={endpoint.isChecked}
                    onClick={(e) =>
                      handleNestedCheckbox(record, endpoint, e.target.checked)
                    }
                  />
                </div>
                <div className="col-3">
                  <span>{endpoint?.name}</span>
                </div>
                <div className="col-3">
                  <span>{endpoint?.ip_address}</span>
                </div>
                <div className="col-2">
                  <span>
                    {endpoint?.isConnected ? "Connected" : "Disconnected"}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty className="nested-colaps" />
        )}
      </div>
    );
  };

  const handleChange = (pagination, filters, sorter) => {
    setParam({
      ...param,
      page: pagination.current,
      page_size: pagination?.pageSize,
      filters,
      sorter,
    });
  };
  console.log("list", list);
  return (
    <div>
      <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-custom">
        <div className="t-filter-search">
          <CustomSearch param={param} setParam={setParam} />
        </div>
      </div>
      <Table
        dataSource={list}
        columns={colums1 ? colums1 : colums}
        className="custom-table-1"
        expandable={{
          expandedRowRender: nestedRecord,
          expandIcon: expandedIcon,
        }}
        pagination={{
          showSizeChanger: true,
          current: param.page,
          pageSize: param.page_size,
          total: totalRecord ? totalRecord : 0,

          // onChange: (page, page_size,filters) => {
          //   setParam({ ...param, page: page, page_size: page_size });
          //   console.log(filters)
          // },
        }}
        onChange={handleChange}
        rowSelection={rowSelection}
        scroll={{ x: 700 }}
        rowKey={"id"}
      />
    </div>
  );
};

export default AddNodeTable;
