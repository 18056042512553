import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../common/CustomInput";
import CustomMapTakeData from "../../../common/UI/CustomMapTakeData";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import { getSiteByIdAction } from "../../../redux/action/otManagement";
import { editSiteSchema } from "../../../utils/validations/otManagementSchema";
import EditSiteLayoutVisualization from "./EditSiteLayoutVisualization";

const EditSite = () => {
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [param, setParam] = useState({ disable: false, nextScreen: false });
  const [formdata, setFormData] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const siteInfo = useSelector((state) => state.ot.siteInfo || []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(editSiteSchema),
  });

  const resetForm = () => {
    reset({});
  };

  const onSubmit = (data) => {
    setParam({ ...param, nextScreen: true });
    setFormData({ ...data, preview });
  };

  const handleImageChange = (files) => {
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target?.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      setPreview(null);
    }
  };

  useEffect(() => {
    dispatch(getSiteByIdAction({ id }));
  }, [id]);

  useEffect(() => {
    reset(siteInfo);
    setPreview(siteInfo?.imageURL);
  }, [id, siteInfo]);

  return (
    <>
      {param?.nextScreen ? (
        <EditSiteLayoutVisualization
          formData={formdata}
          param={param}
          setParam={setParam}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="dark-head-card">
            {/* <div className="d-c-head"> */}
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                <svg
                  className="mr-r10 cursor"
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => navigate(-1)}
                >
                  <path
                    d="M11.875 7.5H3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 11.875L3.125 7.5L7.5 3.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                View Site
              </h3>
              <EditFormIcon param={param} setParam={setParam} />
            </div>
            {/* </div> */}
            <div className="d-c-body d-c-body-1">
              <div className="add-u-form-card">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomInput
                        label={
                          <>
                            {" "}
                            Site Name<span className="required">*</span>{" "}
                          </>
                        }
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="name"
                        error={errors?.name}
                        disabled={!param.disable}
                        fullWidth
                      />{" "}
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomInput
                        label={"Description"}
                        varient="outline"
                        control={control}
                        defaultValue=""
                        name="description"
                        error={errors?.description}
                        disabled={!param.disable}
                        fullWidth
                      />{" "}
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
            <hr className="black-hr" />
            <div className="col-sm-4">
              <div className="form-floating cus-floating-inputs mr-b30 mx-3">
                <div className="form-group">
                  <label htmlFor="image-upload" className="form-label">
                    Site Image<span className="required">*</span>
                  </label>
                  <Controller
                    name="image"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <input
                        id="image-upload"
                        type="file"
                        accept="image/*"
                        className="file-input"
                        onChange={(e) => {
                          field.onChange(e.target.files);
                          handleImageChange(e.target.files);
                        }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors?.image?.message}
                  </FormHelperText>
                </div>

                {preview && (
                  <div className="preview-container">
                    <img
                      src={preview}
                      alt="Preview"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>{" "}
            <hr className="black-hr" />
            <div className="d-c-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={
                        <>
                          {" "}
                          Location<span className="required">*</span>{" "}
                        </>
                      }
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="location"
                      error={errors?.location}
                      fullWidth
                      disabled={!param.disable}
                    />{" "}
                  </div>
                </div>

                {/* <div className="ed-map-block"> */}
                <div className="col-sm-12">
                  <CustomMapTakeData
                    location={[]}
                    search={watch("location")}
                    setValue={setValue}
                  />
                </div>
              </div>

              <div className="col-sm-12">
                <div className="f-btm-btn text-end mr-t30">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={resetForm}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    data-bs-toggle="modal"
                    data-bs-target="#succModal"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default EditSite;
