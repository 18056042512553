import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CustomMultiSelectDevice from "../../common/CustomMultiSelectDevice";
import {
  addNewUserAction,
  empPermissionModuleAction,
  getDeviceListAction,
  getTrustLevelAction,
} from "../../redux/action/action";
import { addUserSchema } from "../../utils/validations/userValidation";
const AddUser = () => {
  const deviceList = useSelector((state) => state.cylenium.deviceList);
  const empModule = useSelector((state) => state.cylenium.empModule);
  const trustLevel = useSelector((state) => state.cylenium.trustLevel);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [
    { value: "1", label: "Guest" },
    { value: "2", label: "Security Analyst" },
    { value: "3", label: "Customer Admin" },
    { value: "4", label: "IT Administrator" },
    { value: "5", label: "System Operator" },
  ];

  const job_title_options = [
    { value: "Contractor", label: "Contractor" },
    { value: "Employee", label: "Employee" },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addUserSchema),
    defaultValues: { devices: [], modules: [], company: "Cylenium" },
  });
  const role_id = watch("role_id");

  const onSubmit = (data) => {
    const payload = {
      ...data,
      flag: "user",
    };
    dispatch(addNewUserAction(payload));
  };
  useEffect(() => {
    dispatch(getDeviceListAction(role_id == 1 ? role_id : 2));
    dispatch(empPermissionModuleAction());
    dispatch(getTrustLevelAction());
  }, [dispatch, role_id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/organization")}
              />
              Add New User
            </h3>
            <button
              type="button"
              className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => navigate("/users/bulk-upload")}
            >
              Bulk Upload
            </button>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Role"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="role_id"
                      error={errors?.role_id}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"First Name*"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="first_name"
                      error={errors?.first_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Last Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="last_name"
                      error={errors?.last_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Company"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="company"
                      error={errors?.company}
                      fullWidth
                      disabled={true}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Email Address*"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="email_address"
                      error={errors?.email_address}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInputPassword
                      label={"Password*"}
                      name="password"
                      varient="outline"
                      control={control}
                      error={errors?.password}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInputPassword
                      label={"Confirm Password*"}
                      name="confirm_password"
                      varient="outline"
                      control={control}
                      error={errors?.confirm_password}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Office phone"}
                      varient="outline"
                      // type="number"
                      control={control}
                      defaultValue=""
                      name="office_phone"
                      error={errors?.office_phone}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Phone Number*"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      // type="number"
                      name="phone_number"
                      error={errors?.phone_number}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Job Title"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="job_title"
                      error={errors?.job_title}
                      options={job_title_options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectDevice
                      label={"Devices"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="devices"
                      multiple={true}
                      error={errors?.devicess}
                      options={deviceList}
                      setValue={setValue}
                      watch={watch}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Trust Level"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="trustLevel"
                      error={errors?.trustLevel}
                      options={trustLevel ? trustLevel : []}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Office Location"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="office_location"
                      error={errors?.office_location}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Work Location"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="work_location"
                      error={errors?.work_location}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                {!["1", "3", 1, 3].includes(role_id) ? (
                  <div className="col-sm-12">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      <CustomMultiSelect
                        label={"Access Permissions"}
                        options={empModule === undefined ? [] : empModule}
                        name="modules"
                        multiple={true}
                        setValue={setValue}
                        watch={watch}
                        // disabled={true}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate("/organization")}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Add New User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddUser;
