import React, { useEffect, useState } from "react";
import { Upload, Button, Input, message } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { getAssetByIdUploadListApi } from "../../../services/api";
import { useDispatch } from "react-redux";
import { spinnerStartStop } from "../../../redux/action/action";
import { toast } from "react-toastify";

const ImageUpload = ({ src, id, type }) => {
  const [fileList, setFileList] = useState([]); // Store uploaded files
  const [uploadedImage, setUploadedImage] = useState(); // Store the image preview
  const [fileName, setFileName] = useState(""); // Store the file name
  const dispatch = useDispatch();
  // Handle image upload
  const handleUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const base64String = e.target.result; // Get the Base64 string
      setUploadedImage(base64String); // Set the preview image
      setFileName(file.name);

      // Simulate sending the Base64 string to an API
      try {
        dispatch(spinnerStartStop(true));
        const response = await getAssetByIdUploadListApi({
          id: id,
          type: type,
          imageURL: base64String,
        })
          .then((res) => {
            setUploadedImage(res.data?.imageURL);
            setFileList([]);
            toast.success("Image uploaded.");
          })
          .catch((err) => {
            toast.error("Image upload failed.");
          });
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        dispatch(spinnerStartStop(false));
      }
    };

    reader.readAsDataURL(file); // Converts file to Base64
    setFileList([file]); // Update file list state
    return false; // Prevent auto-upload
  };

  // Handle delete logic
  const handleDelete = () => {
    setUploadedImage(src);

    setFileName("");
    setFileList([]);
    toast.success("Image removed successfully!");
  };
  useEffect(() => {
    if (src) {
      setUploadedImage(src);
    }
  }, [src]);

  return (
    <div
      style={{ display: "flex", alignItems: "center", gap: "16px" }}
      className="mx-3"
    >
      {/* Image Preview */}
      <div
        style={{
          width: "200px",
          height: "200px",
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
        className="mt-3"
      >
        {uploadedImage ? (
          <img
            src={uploadedImage}
            alt="Uploaded"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <span>No Image</span>
        )}
      </div>

      {/* File Info and Actions */}
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <span style={{ fontWeight: 500 }} className="text-white">
          {type == "gateway" ? "Gateway" : "Asset"} Image*
        </span>

        {/* File Name Display */}
        {fileName ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              padding: "4px 8px",
            }}
          >
            <Input
              value={fileName}
              disabled
              style={{
                border: "none",
                padding: 0,
                backgroundColor: "transparent",
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              danger
              type="text"
              onClick={handleDelete}
            />
          </div>
        ) : (
          <Upload
            beforeUpload={handleUpload}
            fileList={fileList}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
