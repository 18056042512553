import React, { useEffect, useState } from "react";
import { Paper, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "antd";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { updateAssetPictureAction } from "../../redux/action/action";
import { getAssetById } from "../../services/api";
import { setAssetId } from "../../redux/action/action";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const MAX_IMAGE_SIZE = 2048;

const AssetImageUpload = ({}) => {
  const [photo, setPhoto] = useState(null);
  const [existingImageURL, setExistingImageURL] = useState(null);
  const dispatch = useDispatch();
  const imageRef = React.useRef();
  const navigate = useNavigate();
  const assetId = useSelector((state) => state.cylenium.assetId);
  const location = useLocation();

  const fetchExistingImage = async () => {
    try {
      const asset = await getAssetById(assetId);
      if (asset?.data?.imageURL) {
        setExistingImageURL(asset.data.imageURL);
      }
    } catch (error) {
      console.error("Error fetching asset:", error);
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const assetFromPath = pathSegments[pathSegments.length - 1]; // Get the last segment for assetId

    if (!assetId) {
      dispatch(setAssetId(assetFromPath));
    }

    if (assetFromPath) {
      fetchExistingImage();
    }
  }, [dispatch, location.pathname, assetId]);

  const handleImageChange = (event) => {
    const image = event.target.files && event.target.files[0];

    if (!image) {
      return;
    }

    const imageSize = image.size / 1024;
    if (imageSize > MAX_IMAGE_SIZE) {
      toast.error("File Size is greater than 2MB");
      return;
    }

    new Compressor(image, {
      quality: 0.8,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          dispatch(
            updateAssetPictureAction({
              imageURL: base64Data,
              id: assetId,
              imageName: "NewAsset.png",
            })
          )
            .then(() => {
              setPhoto(base64Data);
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            })
            .catch((error) => {
              console.error("Error uploading image:", error);
              toast.error("Failed to upload image");
            });
        };
        reader.readAsDataURL(compressedResult);
      },
    });
  };

  return (
    <Paper
      style={{
        padding: 16,
        height: "100%",
        width: "40%",
        backgroundColor: "#212529",
      }}
    >
      <div style={{ padding: "16px" }}>
        <Typography
          variant="h6"
          style={{ marginBottom: "10px", color: "white" }}
        >
          <IoArrowBack className="mr-r10 cursor" onClick={() => navigate(-1)} />
          Upload Asset Image
        </Typography>
        <div style={{ paddingBottom: 30 }}>
          {photo ? (
            <Image src={photo} width={150} height={150} />
          ) : existingImageURL ? (
            <Image src={existingImageURL} width={150} height={150} />
          ) : (
            <div>No image uploaded yet</div>
          )}
          <div>
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
              ref={imageRef}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => imageRef.current.click()}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default AssetImageUpload;
