import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getSiteById, updateSiteInformation } from '../../services/api';
import { useDispatch, useSelector } from "react-redux";
import { Image } from "antd";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { updateSitePictureAction } from "../../redux/action/action";
import { setSiteId } from '../../redux/action/action';
import { useLocation } from 'react-router-dom';

const MAX_IMAGE_SIZE = 2048;

const SiteEditing = ({  }) => {
  const [photo, setPhoto] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteId = useSelector((state) => state.cylenium.siteId);
  const location = useLocation();

  const [data, setData] = useState({
    name: '',
    location: '',
    description: '',
    assets: [],
    gateways: [],
  });

  const imageRef = React.useRef();

  const updateSiteInfo = async (data) => {
    try {
      const res = await updateSiteInformation(data, siteId);
      console.log(res);
      if (res?.data?.imageURL) {
        setData((prevData) => ({ ...prevData, imageURL: res.data.imageURL }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchSiteInfo = async () => {
    try {
      const res = await getSiteById(siteId);
      const { assets, ...siteData } = res.data;
      const gateways = assets.filter(asset => asset.type === "gateway");
      const filteredAssets = assets.filter(asset => asset.type !== "gateway");

      setData((prevData) => ({
        ...prevData,
        ...siteData,
        assets: filteredAssets,
        gateways,
      }));
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const idFromPath = pathSegments[pathSegments.length - 1]; 

    if (!siteId) {
      dispatch(setSiteId(idFromPath));
      fetchSiteInfo(idFromPath);
    } else {
      fetchSiteInfo(siteId);
    }
  }, [dispatch, location.pathname, siteId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      ...data,
      gateways: data.gateways
    };
    console.log('Updated Data:', updatedData);
    updateSiteInfo(updatedData, siteId);
    setTimeout(() => {
        navigate(`/sites/${siteId}`)
      }, 1000);
  };

  const handleImageChange = (event) => {
    const image = event.target.files && event.target.files[0];

    if (!image) {
      return; 
    }
  
    const imageSize = image.size / 1024;
    console.log(imageSize);
    
    if (imageSize > MAX_IMAGE_SIZE) {
      toast.error("File Size is greater than 2MB");
      return;
    }
    
    new Compressor(image, {
      quality: 0.8,
      success: (compressedResult) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Data = e.target.result;
          dispatch(updateSitePictureAction({ imageURL: base64Data, id: siteId, imageName: 'NewSite.png' }, siteId));
          setPhoto(base64Data);
        };
        reader.readAsDataURL(compressedResult);
      },
    });
  };
  

  return (
    <Paper style={{ padding: 16, height: '100%', width: '40%', backgroundColor: '#212529' }}>
      <div style={{ padding: '16px' }}>
        <Typography variant="h6" style={{ marginBottom: '10px' }}>
          Edit Site
        </Typography>
        <div style={{ paddingBottom: 30 }}>
          {photo ? (
            <Image src={photo} width={150} height={150} />
          ) : (
            data?.imageURL && <Image src={data.imageURL} width={150} height={150} />
          )}
          <div>
            <input
              type="file"
              className="input-select"
              style={{ display: "none" }}
              onChange={handleImageChange}
              ref={imageRef}
            />
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              style={{ width: 150 }}
              onClick={() => imageRef.current.click()}
            >
              {"Upload"}
            </button>
          </div>
        </div>

        <Grid container spacing={2}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={6}>
              <TextField
                name="name"
                label="Name"
                value={data?.name || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="assets"
                label="Assets"
                value={data.assets.length + data.gateways.length|| 0} 
                disabled={true}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="location"
                label="Location"
                value={data?.location || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                name="description"
                label="Description"
                value={data?.description || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                rows={4}
              />
            </Grid>
            <Button type="submit" variant="contained" color="primary">
              Update Info
            </Button>
          </form>
        </Grid>
      </div>
    </Paper>
  );
};

export default SiteEditing;
