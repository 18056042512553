import { IconButton, Tooltip } from "@mui/material";
import { Popconfirm } from "antd";
import moment from "moment";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEye, BsPencil } from "react-icons/bs";

export const batchList = (props) => [
  {
    title: "Id",
    dataIndex: "id",
    sorter: true,
  },
  {
    title: "Batch Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Hosts counts",
    dataIndex: "hostCount",
  },
  {
    title: "Tests Counts",
    dataIndex: "testCount",
  },
  {
    title: "Last Scanned",
    dataIndex: "createdDate",
    render: (data) => {
      return moment(data).format("MM-DD-YYYY HH:mm");
    },
  },
  {
    title: "Connectivity Status",
    dataIndex: "status",
    filterMultiple: false, // Disable multiple filters for this column
    sorter: true,

    filters: [
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "In Progress",
        value: "inProgress",
      },
      {
        text: "Failed",
        value: "failed",
      },
      {
        text: "Completed",
        value: "completed",
      },
    ],
    render: (data, record) => {
      return (
        <div>
          {data === "completed" ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Completed</span>
            </li>
          ) : data === "failed" ? (
            <li>
              <span className="dot bg-red"></span>
              <span className="text-danger">Failed</span>
            </li>
          ) : data === "inProgress" ? (
            <li>
              <span className="dot bg-blue"></span>
              <span className="blue-text">In Progress</span>
            </li>
          ) : data === "pending" && !record.scheduleId ? (
            <li>
              <span className="dot bg-blue"></span>
              <span className="blue-text">Pending</span>
            </li>
          ) : (
            <li>
              <span className="dot bg-orange"></span>
              <span className="text-warning">Scheduled</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (data, record) => {
      return (
        <div>
          {(record?.status === "pending" ||
            record?.status === "completed" ||
            record?.status === "failed") && (
            <Popconfirm
              title="Confirm Delete"
              description="Are you sure you want to delete this batch?"
              onConfirm={() => props.onDelete(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <IconButton>
                  <AiOutlineDelete className="icon" />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          )}
          {record?.status === "pending" && record?.scheduleId && (
            <Tooltip title="Edit">
              <IconButton>
                <BsPencil
                  className="icon"
                  onClick={() =>
                    props.navigate(`/threat_hunting/edit_batch/${record?.id}`)
                  }
                />
              </IconButton>
            </Tooltip>
          )}
          {record?.status !== "pending" && (
            <Tooltip title="View">
              <IconButton>
                <BsEye
                  className="icon"
                  onClick={() =>
                    props.navigate(
                      `/threat_hunting/show_test_progress/${record?.id}`,
                      {
                        state: {
                          batchName: record?.name,
                        },
                      }
                    )
                  }
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    },
  },
];
