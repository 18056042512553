import { Grid, IconButton, Tooltip } from "@mui/material";
import { Popconfirm, Table } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSiteId } from "../../redux/action/action";
import { deleteSiteAction } from "../../redux/action/otManagement";
import { getSiteDashboard } from "../../services/api";
import OtManagementGraph from "../Dashboard/OtManagementGraph";

const OTManagement = () => {
  const [searchText, setSearchText] = useState("");
  const [totalSites, setTotalSites] = useState();
  const [totalGateways, setTotalGateways] = useState();
  const [totalAssets, setTotalAssets] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const deleteSideById = useSelector((state) => state.ot.deleteSideById);

  const fetchSiteDashboard = async () => {
    try {
      const res = await getSiteDashboard();
      setTotalSites(res.data.totalSites);
      setTotalGateways(res.data.totalGateways);
      setTotalAssets(res.data.totalAssets);

      setNewData(res.data.sites);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSiteDashboard();
  }, [deleteSideById]);

  const columns = [
    { dataIndex: "name", title: "Site Name" },
    { dataIndex: "location", title: "Location" },

    {
      dataIndex: "id",
      title: "Action",
      render: (id) => (
        <div>
          <Tooltip title="Details">
            <IconButton
              onClick={() => {
                dispatch(setSiteId(id));
                navigate(`/sites/${id}`);
              }}
            >
              <BsEye />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => navigate(`/edit-site/${id}`)}>
              <CiEdit />
            </IconButton>
          </Tooltip>
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this site?"
            onConfirm={() => dispatch(deleteSiteAction(id))}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <MdDeleteOutline />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const filteredData = newData?.filter((location) =>
    location.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const rows = filteredData?.map((location) => ({
    id: location.id,
    name: location.name,
    location: location.location,
    details: "Details",
  }));

  return (
    <div>
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">OT Management</h3>
        </div>
        <div className="r-btn-block ">
          <button
            onClick={() => navigate("/new-site")}
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
          >
            Create Site
          </button>
        </div>
      </div>

      <Grid container spacing={2} height={500} style={{ paddingTop: 16 }}>
        <Grid item xs={12} md={12}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid>
                  <div className="dashboard-graph" style={{ height: 160 }}>
                    <div className=" row graph-title mb-3  justify-content-center align-item-center">
                      <div className="mx-auto text-center">
                        <h5 className="count-heading"> {totalSites}</h5>
                        <div className="mb-2">Sites</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid>
                  <div className="dashboard-graph" style={{ height: 160 }}>
                    <div className=" row graph-title mb-3  justify-content-center align-item-center">
                      <div className="mx-auto text-center">
                        <h5 className="count-heading"> {totalGateways}</h5>
                        <div className="mb-2">Gateways</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid>
                  <div className="dashboard-graph" style={{ height: 160 }}>
                    <div className=" row graph-title mb-3  justify-content-center align-item-center">
                      <div className="mx-auto text-center">
                        <h5 className="count-heading"> {totalAssets}</h5>
                        <div className="mb-2">Assets</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <div className="dashboard-graph" style={{ height: "100%" }}>
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>Site Location</div>
              </div>
              <OtManagementGraph graphData={newData || []} />
            </div>
            {/* <LoadScript googleMapsApiKey={config.googleMapApiKey}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={12}
              >
                {newData?.map((location) => (
                  <Marker
                    key={location.id}
                    position={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                    onClick={() => handleMarkerClick(location)}
                  />
                ))}

                {infoWindowOpen && (
                  <InfoWindow
                    position={{
                      lat: newData.find((loc) => loc.id === selectedId)
                        .latitude,
                      lng: newData.find((loc) => loc.id === selectedId)
                        .longitude,
                    }}
                    onCloseClick={handleCloseInfoWindow}
                  >
                    <div
                      style={{
                        width: "200px",
                        padding: "10px",
                        overflow: "hidden",
                        paddingLeft: "0px",
                      }}
                    >
                      <img
                        src={
                          newData.find((loc) => loc.id === selectedId).imageURL
                        }
                        alt={newData.find((loc) => loc.id === selectedId).name}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "4px",
                          display: "block",
                          pointerEvents: "none",
                        }}
                      />
                      <p style={{ color: "white", padding: "5px" }}>
                        {newData.find((loc) => loc.id === selectedId).name}
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <div
              className="dashboard-graph"
              style={{ height: "100%", width: "100%" }}
            >
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>List View</div>
              </div>

              <div>
                <div className="table-head d-flex align-items-center justify-content-between mr-t20">
                  <div className="t-filter-search">
                    <Search
                      // onChange={(event) =>
                      //   setParam({ ...param, search: event.target.value })
                      // }
                      placeholder="Search"
                    />
                  </div>
                  {/* <FilterBlock /> */}
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mr-t20">
                    <div className="t-filter-search">
                      <Search
                        placeholder="Search"
                        variant="outlined"
                        onChange={(event) => setSearchText(event.target.value)}
                      />
                    </div>
                  </div> */}
                <div className="mt-3">
                  <div style={{ height: "100%", width: "100%" }}>
                    <Table
                      dataSource={rows}
                      columns={columns}
                      className="custom-table-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <style>
        {`
          .highlight {
            background-color: rgba(128, 128, 128, 0.5); 
          }
        `}
      </style>
    </div>
  );
};

export default OTManagement;
