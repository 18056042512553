import { Box, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { signInAction, spinnerStartStop } from "../../redux/action/action";
import { signInApi, signInTotpApi } from "../../services/api";

const OTPInput = ({ cred, rememberMe }) => {
  const [otp, setOtp] = useState(Array(6).fill("")); // 6 digits OTP state
  const inputRefs = useRef([]); // References to each input

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input automatically
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    const { key } = event;

    // Handle backspace
    if (key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear the current input
      setOtp(newOtp);

      // Move to the previous input if available
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }

    // Handle arrow keys for navigation
    if (key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (key === "ArrowRight" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData
      .getData("text")
      .slice(0, 6)
      .split("");
    const newOtp = [...otp];

    pastedData.forEach((char, index) => {
      if (/^[0-9]$/.test(char)) {
        newOtp[index] = char;
      }
    });

    setOtp(newOtp);

    // Focus on the next input after pasting
    const filledInputIndex = pastedData.length - 1;
    inputRefs.current[filledInputIndex >= 5 ? 5 : filledInputIndex].focus();
  };

  const handleSubmit = () => {
    const finalOtp = otp.join("");
    console.log(finalOtp?.length < 6);
    if (6 > finalOtp?.length) {
      toast.error("Please enter OTP.");
    } else {
      dispatch(
        signInAction({ ...cred, code: finalOtp }, rememberMe ? true : false)
      );
    }
  };
  const [timeLeft, setTimeLeft] = useState(120); // 2 minutes in seconds

  // Timer effect
  useEffect(() => {
    if (timeLeft === 0) {
      // Reset OTP fields and stop timer
      setOtp(Array(6).fill(""));
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);
  // Convert seconds to mm:ss format

  const dispatch = useDispatch();

  return (
    <div>
      <Box
        sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
        onPaste={handlePaste}
      >
        {otp.map((_, index) => (
          <TextField
            key={index}
            inputRef={(el) => (inputRefs.current[index] = el)}
            variant="outlined"
            value={otp[index]}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center", fontSize: "20px" },
            }}
            sx={{ width: "50px", height: "50px" }}
          />
        ))}
      </Box>

      <div className="l-btm-btn mr-t20">
        <button
          type="button"
          className="blue-btn  h46 w-100"
          onClick={handleSubmit}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default OTPInput;
