import { Image, Skeleton } from "antd";
import { useState } from "react";

const ImageWithSkeleton = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div style={{ position: "relative", width: "100%", height: "auto" }}>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "150px", // Match the expected image size
            background: "white", // White background
            borderRadius: "4px",
            padding: "16px",
          }}
        >
          <Skeleton active  paragraph={true} />
        </div>
      )}
      <Image
        src={src}
        alt={alt}
        style={{
          width: "100%",
          height: "auto",
          borderRadius: "4px",
          display: isLoading ? "none" : "block",
        }}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)} // Handle errors as well
      />
    </div>
  );
};

export default ImageWithSkeleton;
