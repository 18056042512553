import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  FormControl,
  FilledInput,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { getAssetById } from "../../services/api";
import GatewayDataTransferChart from "./Charts/DataTransfer";
import GatewaySecurtityEventsWithGraph from "./Charts/SecurityChart";
import GatewayAuthenticationAttempts from "./Charts/AuthenticationAttempts";
import SystemLoad from "./Charts/SystemLoad";
import ResourceUtilization from "./Charts/ResourceUtilization";
import { useDispatch, useSelector } from "react-redux";
import { setAssetId } from "../../redux/action/action";
import { setSiteId } from "../../redux/action/action";
import { useLocation } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { Image } from "antd";

const Assets = () => {
  const navigate = useNavigate();
  const [assetDetails, setAssetDetails] = useState();
  const assetId = useSelector((state) => state.cylenium.assetId);
  const location = useLocation();
  const dispatch = useDispatch();
  const siteId = useSelector((state) => state.cylenium.siteId);

  const getAssetInformation = async (assetId) => {
    try {
      const res = await getAssetById(assetId);
      setAssetDetails(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const idFromPath = pathSegments[pathSegments.length - 2]; // Get the second last segment for siteId
    const assetFromPath = pathSegments[pathSegments.length - 1]; // Get the last segment for assetId

    if (!siteId) {
      dispatch(setSiteId(idFromPath));
    }
    if (!assetId) {
      dispatch(setAssetId(assetFromPath));
    }

    getAssetInformation(assetFromPath);
  }, [dispatch, location.pathname, siteId, assetId]);

  return (
    <Grid container spacing={2} height={500}>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={6}>
            <Paper
              style={{
                padding: 16,
                height: "100%",
                width: "100%",
                backgroundColor: "#212529",
              }}
            >
              <Typography
                spacing={2}
                style={{
                  marginBottom: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <Typography spacing={2} style={{ marginBottom: "10px" }}>
                    <IoArrowBack
                      className="mr-r10 cursor"
                      onClick={() => navigate(-1)}
                    />
                    Asset View
                  </Typography>
                  <Button onClick={() => navigate(`/edit-assets/${assetId}`)}>
                    {" "}
                    <AiOutlineEdit className="icon" />
                  </Button>
                </div>
              </Typography>
              <div className="row">
                <div className="col-3">
                  <Image
                    src={assetDetails?.imageURL}
                    style={{
                      marginLeft: "20px",
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  ></Image>
                </div>{" "}
                <div className="col-9">
                  <div style={{ padding: "16px" }}>
                    {/* <Typography spacing={2} style={{ marginBottom: "20px" }}>
                      Asset Overview
                    </Typography> */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={
                              assetDetails?.assetDetails?.manufacture || ""
                            }
                            label="Manufacturer"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.model || ""}
                            label="Model"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.name || ""}
                            label="Device Name"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.id || ""}
                            label="Serial Number"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.OS || ""}
                            label="OS"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.ip_address || ""}
                            label="IP"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={
                              assetDetails?.assetDetails?.mac_address || ""
                            }
                            label="MAC"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="filled">
                          <TextField
                            variant="outlined"
                            value={assetDetails?.assetDetails?.firmware || ""}
                            label="Firmware Version"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Paper
          style={{
            padding: 16,
            textAlign: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <div className="mt-3"></div>
          {assetId && (
            <>
              <GatewayAuthenticationAttempts />
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          style={{
            padding: 16,
            textAlign: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <div className="mt-3"></div>
          {assetId && (
            <>
              <GatewaySecurtityEventsWithGraph />
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          style={{
            padding: 16,
            textAlign: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <div className="mt-3"></div>
          {assetId && (
            <>
              <GatewayDataTransferChart id={assetId} />{" "}
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper
          style={{
            padding: 16,
            textAlign: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <div className="mt-3"></div>
          {assetId && (
            <>
              <SystemLoad id={assetId} />
            </>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          style={{
            padding: 16,
            textAlign: "center",
            height: "100%",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <div className="mt-3"></div>
          {assetId && (
            <>
              <ResourceUtilization id={assetId} />
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Assets;
