import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useLocation } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../../common/UI/CustomDropdown";
import CustomInput from "../../../common/CustomInput";
import CustomInputPassword from "../../../common/CustomInputPassword";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import { Button } from "antd";
import CustomMultiSelectCloudEndpointsNew from "../../../common/CustomMultiSelectCloudEndpointsNew";
import {
  getCloudEndpointDeviceListAction,
  editCloudEndpointRestAction,
  setSiteId,
} from "../../../redux/action/action";
import { testRestEndpoint } from "../../../services/api";
import CustomSlider from "../../../common/CustomSlider";
import CustomToggle from "../../../common/CustomToggle";
import { restSchema } from "../../../utils/validations/cloudEndpointsValidation";
import { Typography } from "@mui/material";
import { Checkbox, Modal } from "antd";
import CustomKeyValuePairNew from "../../../common/CustomKeyValuePairNew";

const AddRestCloudEndpoint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cloudEndpointsDeviceList = useSelector(
    (state) => state.cylenium.cloudEndpointsDeviceList
  );
  const [basicAuth, setBasicAuth] = useState(1);
  const [openTestModal, setOpenTestModal] = useState(false);
  const [error, setError] = useState(false);
  const [isPostMethod, setIsPostMethod] = useState(false);
  const [param, setParam] = useState({ disable: true });

  const options = [
    { value: "1", label: "No Auth" },
    { value: "2", label: "Basic Auth" },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      method: "GET",
    },
    resolver: yupResolver(restSchema),
  });

  const defaultValue = [
    {
      id: 146,
      name: "ICX7150-C12-Switch",
      type: "asset",
    },
  ];

  const onSubmit = (data) => {
    console.log("Form submitted with data:", data);
    const devices = watch("devices") || [];
    const formattedDevices = devices
      .map((id) => {
        const selectedDevice = cloudEndpointsDeviceList.find(
          (device) => device.id === id
        );
        return {
          id: selectedDevice?.id,
          name: selectedDevice?.name,
          type: selectedDevice?.type,
        };
      })
      .filter((device) => device);

    const payload = {
      ...data,
      devices: formattedDevices,
    };

    console.log("Formatted payload:", payload);
    dispatch(editCloudEndpointRestAction(payload));
    setTimeout(() => {
      navigate("/cloud-endpoints");
    }, 1000);
  };
  const handleTestAuth = async () => {
    const { username, password, url, method } = watch();
    if (!username || !password || !url) {
      console.log("Please fill all fields.");
      return;
    }

    try {
      const res = await testRestEndpoint({ username, password, url, method });
      console.log(res);
      setOpenTestModal(true);
    } catch (error) {
      console.log("There was an error:", error);
      setError(true);
      setOpenTestModal(true);
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsPostMethod(checked);
    setValue("method", checked ? "POST" : "GET");
  };

  useEffect(() => {
    dispatch(getCloudEndpointDeviceListAction());
    console.log(cloudEndpointsDeviceList);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/cloud-endpoint-options")}
              />
              Create Cloud Endpoint
            </h3>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name={"name"}
                      error={errors?.name}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomMultiSelectCloudEndpointsNew
                      options={cloudEndpointsDeviceList}
                      label={"Devices"}
                      name="devices"
                      multiple={true}
                      setValue={setValue}
                      watch={watch}
                      disabled={!param.disable}
                      error={errors?.devices}
                      defaultValue={defaultValue}
                    />
                  </div>
                </div>
                <hr className="black-hr" />

                <div style={{ padding: 0 }} className="col-sm-12 col-md-6">
                  <div
                    style={{ padding: 15, justifyContent: "left" }}
                    className="form-floating cus-floating-inputs mr-b30"
                  >
                    <CustomToggle
                      disabled={!param.disable}
                      defaultValue={false}
                      param={param}
                      control={control}
                      name="syncInBatches"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomSlider
                      name="batchInterval"
                      control={control}
                      defaultValue={watch("batchInterval") || "15"}
                      param={param}
                      disabled={!param.disable}
                    />
                  </div>
                </div>
                <hr className="black-hr" />

                <div
                  style={{ color: `rgba(255, 255, 255, 0.4)` }}
                  className="form-floating cus-floating-inputs mr-b30"
                >
                  REST
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"URL"}
                      varient="outline"
                      control={control}
                      disabled={!param.disable}
                      defaultValue=""
                      name="url"
                      error={errors?.url}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div
                    style={{
                      color: `rgba(255, 255, 255, 0.4)`,
                      marginTop: -10,
                    }}
                    className=""
                  >
                    METHOD
                    <Checkbox
                      style={{ padding: 25 }}
                      checked={isPostMethod}
                      onChange={handleCheckboxChange}
                      control={control}
                      setValue={setValue}
                      disabled={!param.disable}
                      defaultValue="GET"
                      value="GET"
                      error={errors?.method}
                    />
                    POST
                  </div>
                </div>

                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Timeout"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="timeout"
                      error={errors?.timeout}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"authType"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue={options[0].value}
                      name="authType"
                      error={errors?.authType}
                      options={options}
                      fullWidth
                      onChange={(selectedOption) => {
                        const value = selectedOption.target.value;
                        setValue("authType", value);
                        setBasicAuth(value);
                      }}
                    />
                  </div>
                </div>

                {basicAuth == 2 ? (
                  <>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomInput
                          label={"Username"}
                          varient="outline"
                          disabled={!param.disable}
                          control={control}
                          defaultValue=""
                          name="username"
                          error={errors?.username}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomInputPassword
                          label={"Password"}
                          name="password"
                          varient="outline"
                          disabled={!param.disable}
                          control={control}
                          error={errors?.password}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 text-end">
                      <button
                        size="large"
                        type="primary"
                        // className="text-end"
                        onClick={() => handleTestAuth()}
                        className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 text-end"
                      >
                        Test
                      </button>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12" style={{ marginTop: -20 }}>
                {control ? (
                  <CustomKeyValuePairNew
                    disabled={!param.disable}
                    control={control}
                    setValue={setValue}
                    param={param}
                  />
                ) : (
                  <div>Error: Control is not initialized.</div>
                )}
              </div>

              <SaveAndCancelButton
                onClick={() => handleSubmit(onSubmit)}
                param={param}
                cancelAction={() => navigate("/cloud-endpoints")}
              />

              <Modal
                open={openTestModal}
                onCancel={() => setOpenTestModal(false)}
                onOk={() => setOpenTestModal(false)}
              >
                {!error ? (
                  <Typography variant="h6" component="h2">
                    Success
                  </Typography>
                ) : (
                  <Typography variant="h6" component="h2">
                    Error occured. Please try again.
                  </Typography>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddRestCloudEndpoint;
