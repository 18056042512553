import React, { useState, useEffect } from "react";
import { Upload, Button } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

const CustomPrivateKey = ({
  param = { disable: true },
  initialFileData,
  onFilesChange,
}) => {
  const [fileData, setFileData] = useState(initialFileData);

  const beforeUpload = (file) => true;

  const handleChange = (info) => {
    const updatedFile = info.fileList[0];

    if (updatedFile) {
      console.log("Updated file:", updatedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        setFileData({
          privateKey: base64String,
          privateKeyName: updatedFile.name,
        });

        console.log("New file data:", {
          privateKey: base64String,
          privateKeyName: updatedFile.name,
        });
      };
      reader.readAsDataURL(updatedFile.originFileObj);
    } else {
      setFileData({ privateKey: null, privateKeyName: "" });
    }
  };

  const handleUpload = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess(null, file);
    }, 1000);
  };

  useEffect(() => {
    onFilesChange(fileData);
  }, [fileData, onFilesChange]);

  useEffect(() => {
    setFileData(initialFileData);
  }, [!initialFileData]);

  return (
    <div>
      <Upload
        fileList={
          fileData.privateKeyName
            ? [{ name: fileData.privateKeyName, status: "done" }]
            : []
        }
        beforeUpload={beforeUpload}
        onChange={handleChange}
        showUploadList={true}
        disabled={!param.disable}
        customRequest={handleUpload}
        maxCount={1}
        onRemove={false}
      >
        <Button
          // style={{ color: `rgba(255, 255, 255, 0.4)`, width: 300 }}
          type="dashed"
          icon={<UploadOutlined />}
          variant="link"
          disabled={!param.disable}
          color="primary"
          style={{ width: 300 }}
        >
          Choose Private Key*
        </Button>
      </Upload>
    </div>
  );
};

export default CustomPrivateKey;
