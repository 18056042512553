import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editDevice } from "../../../../utils/validations/adminDevice";
import {
  editDeviceDetailsAction,
  getDeviceDetailsByIdAction,
  getDeviceTypeAction,
  getTrustLevelAction,
} from "../../../../redux/action/action";
import CustomInput from "../../../../common/CustomInput";
import CustomDropdown from "../../../../common/CustomDropdown";
import CustomMap from "../../../../common/UI/CustomMap";
import { IoArrowBack } from "react-icons/io5";
const EditDevice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [param, setParam] = useState({
    disable: false,
  });
  const record = useSelector((state) => state.cylenium.deviceDetailsByID);
  const deviceTypes = useSelector((state) => state.cylenium.deviceTypes);
  const trustLevel = useSelector((state) => state.cylenium.trustLevel);

  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(editDevice),
  });

  const options = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  useEffect(() => {
    dispatch(getDeviceDetailsByIdAction(id));
    dispatch(getDeviceTypeAction());
    dispatch(getTrustLevelAction());
  }, [id]);

  useEffect(() => {
    resetForm();
  }, [record, trustLevel]);
  useEffect(() => {
    resetForm();
  }, [trustLevel]);

  const resetForm = () => {
    console.log("Reset", record);
    reset(record);
    setValue("trustLevel", record?.trustLevel);
  };

  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  const onSubmit = (data) => {
    const payload = {
      device_id: record.device_id,
      device_name: data.device_name,
      status: data.status,
      device_type_id: data.device_type_id,
      trustLevel: data.trustLevel,
    };
    dispatch(editDeviceDetailsAction(payload));
  };

  const onSubmitCustom = (data) => {
    console.log(data);
    const onlyDeviceIds = [id];
    const all_ruleset = [];
    // oldRuleset.map((old) => {
    //   all_ruleset.push(old);
    // });
    // selectedDevices?.forEach((record) => {
    //   if (record.checked && !onlyDeviceIds.includes(record.device_id)) {
    //     onlyDeviceIds.push(record.device_id);
    //   }
    // });
    // dispatch(
    //   addRulesetAction({
    //     devices: onlyDeviceIds,
    //     rules: all_ruleset,
    //     // devices: sgsx
    //   })
    // );
  };
  console.log(watch("trustLevel"), trustLevel);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dark-head-card">
        {/* <div className="d-c-head"> */}
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate("/device")}
            />
            Device Details
          </h3>
          {!param.disable ? (
            <button
              type="button"
              className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => handleEdit(true)}
            >
              Edit
            </button>
          ) : (
            <button
              type="button"
              className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => handleEdit(false)}
            >
              Cancel
            </button>
          )}
        </div>
        {/* </div> */}
        <div className="d-c-body">
          <div className="static-info">
            <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
              Device ID
            </label>
            <h5 className="font-size12 Inter-Bold text-white">
              {record?.device_id}
            </h5>
          </div>
        </div>
        <hr className="black-hr" />

        <div className="d-c-body">
          <div className="add-u-form-card">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomInput
                    label={"Device Name"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="device_name"
                    error={errors?.device_name}
                    fullWidth
                    disabled={!param.disable}
                  />{" "}
                </div>
              </div>{" "}
              <div className="col-sm-4">
                <div
                  className={`form-floating cus-floating-inputs mr-b30  ${
                    !param.disable ? "disabled" : ""
                  }`}
                >
                  <CustomDropdown
                    label={"Device Type"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="device_type_id"
                    error={errors?.device_type_id}
                    options={[
                      {
                        value: "add_new",
                        label: "Add New Type +",
                        type: "link",
                        link: `/device/add_device_type/${id}`,
                      },
                      ...deviceTypes,
                    ]}
                    fullWidth
                    disabled={!param.disable}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div
                  className={`form-floating cus-floating-inputs mr-b30  ${
                    !param.disable ? "disabled" : ""
                  }`}
                >
                  <CustomDropdown
                    label={"Status"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="status"
                    error={errors?.status}
                    options={options}
                    fullWidth
                    disabled={!param.disable}
                  />
                </div>
              </div>
              {watch("trustLevel") ? (
                <div className="col-sm-4">
                  <div
                    className={`form-floating cus-floating-inputs mr-b30  ${
                      !param.disable ? "disabled" : ""
                    }`}
                  >
                    <CustomDropdown
                      label={"Trust Level"}
                      varient="outline"
                      control={control}
                      name="trustLevel"
                      error={errors?.trustLevel}
                      options={trustLevel ? trustLevel : []}
                      fullWidth
                      disabled={!param.disable}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <hr className="black-hr" style={{ marginTop: -20 }} />
        <div className="d-c-body">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"Location"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="location"
                  error={errors?.location}
                  fullWidth
                  disabled={true}
                />{" "}
              </div>
            </div>
            {/* <div className="col-sm-4">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"IP Address"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="ip_address"
                  error={errors?.ip_address}
                  fullWidth
                  disabled={true}
                />{" "}
              </div>
            </div> */}
            {/* <div className="ed-map-block"> */}
            <div className="col-sm-12">
              <CustomMap
                location={[record]}
                search={watch("location")}
                setValue={setValue}
              />
            </div>
          </div>

          <div className="col-sm-12">
            {param.disable ? (
              <div className="f-btm-btn text-end mr-t30">
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={resetForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#succModal"
                >
                  Submit
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditDevice;
