import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelectCloudEndpointsNew({
  options,
  label,
  name,
  multiple,
  watch,
  disabled,
  setValue,
  defaultValue = [options.filter(option => defaultValue.includes(option.name))],
}) {

  const [selectedValue, setSelectedValue] = useState([options.filter(option => defaultValue.includes(option.name))]);

  useEffect(() => {
    if (defaultValue.length > 0) {
        const defaultSelected = options.filter(option => defaultValue.includes(option.id));
        setSelectedValue(defaultSelected);
        setValue(name, multiple ? defaultSelected.map(({ id }) => id) : defaultSelected[0]?.id || null);
    }
    else {
      setSelectedValue(defaultValue);
    }
}, []);



const onChange = (event, newValue) => {
  console.log("User selected:", newValue);
  setSelectedValue(newValue);
  const selectedIds = multiple ? newValue.map(option => option.id) : newValue?.id || null;
  console.log("Setting form value to:", newValue, selectedIds);
  setValue(name, selectedIds);
};

  

  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple}
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedValue} 
      getOptionLabel={(option) => option.name} 
      onChange={onChange} 
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label || ""} />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.id}
          value={option.name}
          sx={{ justifyContent: "space-between" }}
        >
          <Checkbox checked={selected} />
          {option.name}
        </MenuItem>
      )}
    />
  );
}