import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cloudEndpointColumn } from "../../../common/Tables/cloudEndpointColumns";
import CustomTable from "../../../common/UI/CustomTable";
import {
deleteCloudEndpointAction,
getCloudEndpointListAction
} from "../../../redux/action/action";



const CloudEndpoints = () => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cloudList = useSelector((state) => state.cylenium.cloudEndpoints);


  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getCloudEndpointListAction(QueryString.stringify(payload)));
    console.log(cloudList)
  };

  const onDelete = (id) => {
    dispatch(deleteCloudEndpointAction(id));
  };

  useEffect(() => {
    getRecords();
  }, [param]);


  return (
    <div className="user-wrap">
           <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Cloud Endpoints</h3>
        </div>
      <div className="dark-table-wrap">
      <div className="table-head d-flex align-items-center justify-content-between mr-t20 mb-2">
        
        <div className="t-filter-search">
          <Search
           placeholder="Search"
            onChange={(event) =>
              setParam({ ...param, search: event.target.value })
            }
          />
        </div>
        <div>
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate('/cloud-endpoint-options')}
          >
            Add New Endpoint
          </button>
        </div>
      </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              records={cloudList}
              columns={cloudEndpointColumn({ navigate, onDelete, dispatch })}
              param={param}
              setParam={setParam}
              className="custom-table-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudEndpoints;

