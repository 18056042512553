import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  FilledInput,
  Button,
  TextField,
} from "@mui/material";
import Search from "antd/es/transfer/search";
import { getSiteById } from "../../services/api";
import { AiOutlineEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { setAssetId } from "../../redux/action/action";
import { setSiteId } from "../../redux/action/action";
import { useLocation } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { Table } from "antd";
import { Title } from "react-jsx-highcharts";

const SiteView = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const siteId = useSelector((state) => state.cylenium.siteId);
  console.log(siteId);
  const API = async () => {
    try {
      const res = await getSiteById(siteId);
      console.log(siteId);
      setNewData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const idFromPath = pathSegments[pathSegments.length - 1];

    if (!siteId) {
      dispatch(setSiteId(idFromPath));
      API(idFromPath);
    } else {
      API(siteId);
    }
  }, [dispatch, location.pathname, siteId]);

  const handlePinClick = (location) => {
    setSelectedId(location.id);
  };
  const tableColumns = [
    {
      dataIndex: "name",
      title: "Name",
      hideable: false,
      headerClassName: "super-app-theme--header",
      flex: 0.6,
      resizable: false,
      renderCell: ({ row: { name } }) => <div>{name}</div>,
    },
    {
      dataIndex: "model",
      title: "Model/ID",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
    },
    {
      dataIndex: "type",
      title: "Type",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
    },
    {
      dataIndex: "status",
      title: "Status",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      resizable: false,
      render: ({ status }) => (
        <div>{status ? <div>On</div> : <div>Off</div>}</div>
      ),
    },
  ];

  const filteredData = newData?.assets?.filter(
    (location) =>
      location.name.toLowerCase().includes(searchText.toLowerCase()) ||
      location.type.toLowerCase().includes(searchText.toLowerCase()) ||
      location.model.toLowerCase().includes(searchText.toLowerCase())
  );

  const rows = filteredData?.map((location) => ({
    id: location.id,
    name: location.name,
    model: location.model,
    type: location.type,
    status: location.status,
  }));

  return (
    <div>
      <Grid container spacing={2} height={500} style={{ padding: 16 }}>
        <Grid item xs={12} md={6} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}></Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Paper
              style={{
                padding: 16,
                height: "auto",
                width: "100%",
                backgroundColor: "#212529",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px",
                }}
              >
                <Typography spacing={2} style={{ marginBottom: "10px" }}>
                  <IoArrowBack
                    className="mr-r10 cursor"
                    onClick={() => navigate("/otmanagement")}
                  />
                  Site View
                </Typography>
              </div>
              <div
                style={{ position: "relative", width: "100%", height: "400px" }}
              >
                <img
                  src={newData?.imageURL}
                  alt="Upload an image"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                {newData?.assets?.map((location) => (
                  <div
                    key={location.id}
                    onClick={() => handlePinClick(location)}
                    style={{
                      position: "absolute",
                      left: `${location.position?.[0] % 100}%`,
                      top: `${location.position?.[1] % 100}%`,
                      cursor: "pointer",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "blue",
                      borderRadius: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                ))}
                {selectedId && (
                  <div
                    style={{
                      position: "absolute",
                      left: `${
                        newData?.assets?.find((loc) => loc.id === selectedId)
                          .position?.[0] % 100
                      }%`,
                      top: `${
                        newData?.assets?.find((loc) => loc.id === selectedId)
                          .position?.[1] % 100
                      }%`,
                      backgroundColor: "#212529",
                      color: "white",
                      borderRadius: "5%",
                      padding: "10px",
                      transform: "translate(-50%, -100%)",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const selectedAsset = newData?.assets.find(
                        (loc) => loc.id === selectedId
                      );
                      if (selectedAsset && selectedAsset.type === "gateway") {
                        dispatch(setAssetId(selectedId));
                        navigate(`/sites/${siteId}/asset/${selectedId}`);
                      }
                    }}
                  >
                    <img
                      src={
                        newData?.assets?.find((loc) => loc.id === selectedId)
                          .imageURL
                      }
                      alt={
                        newData?.assets?.find((loc) => loc.id === selectedId)
                          .imageName
                      }
                      style={{
                        width: "100px",
                        height: "auto",
                        borderRadius: "4px",
                      }}
                    />
                    <p style={{ fontSize: 12 }}>
                      {newData.assets.find((loc) => loc.id === selectedId).name}
                    </p>
                  </div>
                )}
              </div>
              <div style={{ padding: "16px" }}>
                <Typography spacing={2} style={{ marginBottom: "20px" }}>
                  Site Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.name || ""}
                        label="Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.totalAssets || ""}
                        label="Assets"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <TextField
                        variant="outlined"
                        value={newData?.location || ""}
                        label="Location"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl multiline variant="filled">
                      <TextField
                        variant="outlined"
                        value={newData?.description || ""}
                        label="Description"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{
            padding: 16,
            textAlign: "center",
            height: "auto",
            width: "100%",
            backgroundColor: "#212529",
          }}
        >
          <Paper style={{ padding: 16, backgroundColor: "#212529" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <div className="user-wrap">
                <div className="page-head d-flex align-items-center justify-content-between">
                  <div className="page-title">
                    <h3 className="font-size18 text-white Inter-Bold">
                      List View
                    </h3>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-between mr-t20">
                    <div className="t-filter-search">
                      <Search
                        placeholder="Search"
                        variant="outlined"
                        onChange={(event) => setSearchText(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <div style={{ height: "100%", width: "100%" }}>
                      <Table
                        dataSource={rows}
                        columns={tableColumns}
                        className="custom-table-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <style>
        {`
          .highlight {
            background-color: rgba(128, 128, 128, 0.5); 
          }
        `}
      </style>
    </div>
  );
};

export default SiteView;
