import { TextField } from "@mui/material";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createAzureDeviceAction } from "../redux/action/action";

const CustomInputConnectionString = ({
  control,
  setValue,
  name,
  error,
  param,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [hubConnectionString, setHubConnectionString] = useState("");
  const [hubDeviceId, setHubDeviceId] = useState("");
  const [newDeviceConnectionString, setNewDeviceConnectionString] =
    useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setHubConnectionString("");
    setHubDeviceId("");
  };

  const handleApiCall = async () => {
    try {
      const response = await dispatch(
        createAzureDeviceAction({
          hubConnectionString,
          hubDeviceId,
        })
      );

      console.log(response); // Check the response structure

      // Check if response is valid
      if (response && response.data) {
        const connectionString = response.data.deviceConnectionString; // Extract the connection string
        setNewDeviceConnectionString(connectionString); // Set the new connection string
        setValue(name, connectionString); // Set the new value in the form
      } else {
        console.error("No data in response:", response);
      }

      handleClose(); // Close the modal
    } catch (error) {
      console.error("Error creating device:", error);
      // Handle the error appropriately (e.g., show a notification)
    }
  };

  const fakeApiCall = (connectionString, deviceId) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(`Connection_String_For_${deviceId}`); // Simulated response
      }, 1000);
    });
  };

  return (
    <div className="form-floating cus-floating-inputs mr-b30 row">
      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <div className="col-sm-6">
          <Controller
            name={name}
            control={control}
            defaultValue={newDeviceConnectionString}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Device Connection String"
                error={!!error}
                helperText={error?.message}
                fullWidth
                disabled={!param.disable}
                style={{ marginRight: 16 }} // Add margin to separate the text field and button
              />
            )}
          />
        </div>
        <button
          style={{
            width: "120px",
          }} // Add some space to the left
          onClick={handleOpen}
          disabled={!param.disable}
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10 mb-1"
        >
          New Device
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        title="AZURE IoT HUB Device Registration"
        footer={[
          <Button type="default" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={handleApiCall}
            style={{ marginLeft: "10px" }}
          >
            Register Device
          </Button>,
        ]}
      >
        <TextField
          margin="normal"
          label="Connection String"
          fullWidth
          variant="outlined"
          value={hubConnectionString}
          onChange={(e) => setHubConnectionString(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Device Id"
          fullWidth
          variant="outlined"
          value={hubDeviceId}
          onChange={(e) => setHubDeviceId(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default CustomInputConnectionString;
