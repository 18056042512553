import { IconButton, Tooltip } from "@mui/material";
import { Popconfirm, Switch } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";

export const cloudEndpointColumn = (props) => [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Service Provider Name",
    dataIndex: "serviceProviderName",
  },

  {
    title: "Assets Assigned",
    dataIndex: "assignedAssets",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (data) => {
      return (
          <Switch checked={data} size="small"/>
      );
  }},
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="Activity">
            <IconButton className="mx-1">
              <BsEye
                className="icon"
                onClick={() =>{
                  const service = record?.serviceProviderName.replace(/ /g, '');
                  props.navigate(
                    `/cloud-endpoint-options/${service}/${record?.id}`
                  )}
                }
              />
            </IconButton>
          </Tooltip>
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this group?"
            onConfirm={() => props.onDelete(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];
