import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../../common/CustomInput";
import CustomMultiSelectDevice from "../../../../common/CustomMultiSelectDevice";
import CustomMapTakeData from "../../../../common/UI/CustomMapTakeData";
import {
  addDomainAction,
  getDeviceListAction,
} from "../../../../redux/action/action";

const AddDomain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [param, setParam] = useState({
    disable: true,
  });
  const record = useSelector((state) => state.cylenium.deviceDetailsByID);
  const deviceList = useSelector((state) => state.cylenium.deviceList);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    // resolver: yupResolver(addDomainSchema),
    defaultValues: { devices: [] },
  });

  useEffect(() => {
    resetForm();
  }, [record]);

  const resetForm = () => {
    reset(record);
  };

  const onSubmit = (data) => {
    dispatch(addDomainAction(data));
  };
  useEffect(() => {
    dispatch(getDeviceListAction(2));
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dark-head-card">
        {/* <div className="d-c-head"> */}
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <svg
              className="mr-r10 cursor"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => navigate(-1)}
            >
              <path
                d="M11.875 7.5H3.125"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 11.875L3.125 7.5L7.5 3.125"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Create New Domain{" "}
          </h3>
        </div>
        {/* </div> */}

        <div className="d-c-body d-c-body-1">
          <div className="add-u-form-card">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomInput
                    label={"Domain Name"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="domain_name"
                    error={errors?.domain_name}
                    fullWidth
                    disabled={!param.disable}
                  />{" "}
                </div>
              </div>
              <div className="col-sm-8">
                <div className="form-floating cus-floating-inputs mr-b30">
                  {/* <MultiSelectDevices
                    label={"Devices"}
                    varient="outline"
                    control={control}
                    placeholder="Devices"
                    defaultValue=""
                    name="devices"
                    error={errors?.devices}
                    options={deviceList}
                    mode={"multiple"}
                    fullWidth
                  />{" "} */}
                  <CustomMultiSelectDevice
                    label={"Devices"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="devices"
                    multiple={true}
                    error={errors?.devicess}
                    options={deviceList}
                    setValue={setValue}
                    watch={watch}
                    fullWidth
                  />{" "}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        <hr className="black-hr" />
        <div className="d-c-body">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-floating cus-floating-inputs mr-b30">
                <CustomInput
                  label={"Location"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="location"
                  error={errors?.location}
                  fullWidth
                  // disabled={true}
                />{" "}
              </div>
            </div>

            {/* <div className="ed-map-block"> */}
            <div className="col-sm-12">
              <CustomMapTakeData
                location={[]}
                search={watch("location")}
                setValue={setValue}
              />
            </div>
          </div>

          <div className="col-sm-12">
            {param.disable ? (
              <div className="f-btm-btn text-end mr-t30">
                <button
                  type="button"
                  className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={resetForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#succModal"
                >
                  Submit
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDomain;
