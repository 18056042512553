import React from "react";
import { Typography, Paper } from "@mui/material";
import awsIoT from "../../../assets/images/awsIoT.png";
import azure from "../../../assets/images/azure.png";
import orangeCloud from "../../../assets/images/orangeCloud.png";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

const CloudEndpointOptions = ({}) => {
  const navigate = useNavigate();

  return (
    <Paper
      style={{
        padding: 16,
        height: "100%",
        width: "100%",
        backgroundColor: "#212529",
      }}
    >
      <div style={{ padding: "16px" }}>
        <h3
          style={{ paddingBottom: "16px" }}
          className="font-size18 text-white"
        >
          <IoArrowBack
            className="mr-r10 cursor"
            onClick={() => navigate("/cloud-endpoints")}
          />
          Select Cloud Endpoints
        </h3>

        <div style={{ display: "flex" }} className="cloud-endpoint">
          <img
            style={{ width: "100px", height: "100px", margin: "30px" }}
            src={awsIoT}
            className=""
            alt=""
            onClick={() => navigate("/cloud-endpoint-options/aws/new")}
          />
          <img
            style={{ width: "150px", height: "100px", margin: "30px" }}
            src={azure}
            alt=""
            onClick={() => navigate("/cloud-endpoint-options/AzureHub/new")}
          />
          <img
            style={{ width: "100px", height: "100px", margin: "30px" }}
            src={orangeCloud}
            alt=""
            onClick={() => navigate("/cloud-endpoint-options/rest/new")}
          />
        </div>
      </div>
    </Paper>
  );
};

export default CloudEndpointOptions;
