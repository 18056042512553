import React, { useCallback, useLayoutEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
import markerIcon from "../../assets/images/device.svg";
import { useState } from "react";
import config from "../../config/config";

function CustomMap({ location }) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapContainerStyle = {
    height: "350px",
    width: "100%",
    borderRadius: 10,
  };

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseClick = () => {
    setSelectedMarker(null);
  };
  const hasLocation =
    location &&
    location.length > 0 &&
    location[0].latitude &&
    location[0].longitude;

  const defaultCenter = {
    lat: 0, // Default latitude
    lng: 0, // Default longitude
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ["visualization"],
  });

  const [map, setMap] = useState(null);

  useLayoutEffect(() => {
    if (map && location.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      location.forEach((device) => {
        const lat = parseFloat(device.latitude);
        const lng = parseFloat(device.longitude);
        if (!isNaN(lat) && !isNaN(lng)) {
          bounds.extend({ lat, lng });
        } else {
          console.error("Invalid coordinates for device:", device);
        }
      });
      map.fitBounds(bounds);

      // Ensure the zoom level is not too high
      const listener = window.google.maps.event.addListener(map, "idle", () => {
        if (map.getZoom() > 10) {
          map.setZoom(2); // Adjust this zoom level as necessary
        }
        window.google.maps.event.removeListener(listener);
      });
    }
  }, [map, location]);

  const onLoad = useCallback(
    (map) => {
      if (location.length === 0) {
        map.setCenter({ lat: 30.337844, lng: 59.955413 });
        map.setZoom(1);
      }
      setMap(map);
    },
    [location]
  );

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={
            hasLocation
              ? {
                  lat: parseFloat(location[0].latitude),
                  lng: parseFloat(location[0].longitude),
                }
              : defaultCenter
          }
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ maxZoom: 40, minZoom: 3 }}
          zoom={2}
        >
          {location.map((marker, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              onClick={() => onMarkerClick(marker)}
              // icon={markerIcon}
            />
          ))}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.latitude),
                lng: parseFloat(selectedMarker.longitude),
              }}
              onCloseClick={onCloseClick}
            >
              <div className="map-modal">
                <div className="">
                  <div className="">
                    <div>
                      <span className="heading-label">Device Name</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.device_name}{" "}
                      </span>
                    </div>
                  </div>
                  <div className=" mt-2">
                    <div>
                      <span className="heading-label">Device Address</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.location}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </>
  );
}

export default CustomMap;
