import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import useElementWidth from "../../../common/Hooks/useElementWidth";

const LineChart = ({ dataSource }) => {
  const themes = useSelector((state) => state.cylenium.themes);

  const lineChartData = {
    options: {
      chart: {
        id: "line-chart",
        toolbar: {
          autoSelected: "pan",
          tools: {
            pan: false, // Enables panning
            reset: true, // Adds a reset button to the toolbar
          },
        },
      },

      xaxis: {
        categories: dataSource?.categories ? dataSource?.categories : [],
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      stroke: {
        width: [2, 2],
        curve: "straight",
      },
      colors: ["#FF5733", "#ffc107"],
    },
  };
  const [divWidth, divRef] = useElementWidth();

  return (
    <div className="row" ref={divRef}>
      <div className="p-3">
        <Chart
          options={lineChartData.options}
          series={dataSource?.series ? dataSource?.series : []}
          type="line"
          width={divWidth - 40}
          height="270"
        />
      </div>
    </div>
  );
};

export default LineChart;
